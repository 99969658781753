import React, { useState } from "react";
import './FaqComponent.css';

function FaqComponent(props) {

    let component = props.data.flatData;

    const [activeaccordian, setActiveaccordian] = useState(null);
    function handleClick(index) {
        if (activeaccordian === index) {
            setActiveaccordian(null);
        }
        else {
            setActiveaccordian(index);

        }
    }
    return (
        <section className="page-layout-component" style={{ backgroundColor: (component.backgroundColor && component.backgroundColor.length > 0 ? component.backgroundColor[0].flatData.colorValue : '') }}>
            <section className="faqshort">
                <div className="faqsection">
                    <h2>Frequently Asked Questions</h2>
                    
                    {component.faq.map((item, index) => {
                        return (
                            <div key={index} id="accordian" className={'accordionItem ' + (index === activeaccordian ? 'open' : 'close')}>
                                <hr />
                                <h3 className="accordionItemHeading" onClick={() => handleClick(index)} >{item.flatData.faqQuestion}</h3>
                                <div className="accordionItemContent">
                                    <div dangerouslySetInnerHTML={{ __html: item.flatData.faqShortAnswer }}></div>
                                </div>
                            </div>
                        )
                    })}
                    <hr />
                </div>
                <div className="contactusshort">
                    <h2>Still have questions?</h2>
                    <a href={'tel:'+ (component.contactDetials && component.contactDetials.length > 0 ? component.contactDetials[0].flatData.telNo : '')}>
                        <div className="rowflex">
                            <div className="contactusicon">
                                <img src="https://cms.computicket.com/api/assets/knect/f1a7ec64-4d60-406a-975f-7aa17115b206" alt="" />
                            </div>
                            Call us: <br/>
                            Dial 135 free from k'nect mobile<br/>
                            or {(component.contactDetials && component.contactDetials.length > 0 ? component.contactDetials[0].flatData.telNo : '')} from any other number
                        </div>
                    </a>
                    <br/>
                    <a href={'mailto: '+(component.contactDetials && component.contactDetials.length > 0 ? component.contactDetials[0].flatData.email : '')}>
                        <div className="rowflex">
                            <div className="contactusicon"><img
                                src="https://cms.computicket.com/api/assets/knect/3dd9a442-7d62-4e86-b905-6d5cf65af1ad" alt="" /></div>Email
                    us:
                    <br />{(component.contactDetials && component.contactDetials.length > 0 ? component.contactDetials[0].flatData.email : '')}
                        </div>
                    </a>

                    <div className="rowflex">
                        <a href="/help-and-support">
                            <div className="contactusbutton">FAQS</div>
                        </a>

                    </div>
                </div>
            </section>

        </section>
    )
}

export default FaqComponent;