import React from 'react'
import Downshift from 'downshift'
import he from 'he';



function AutoComplete(props) {
    const sendData = (id) => {
        props.parentCallback(id);
    }



    var component = props.data;
    const items = [


    ]
    if (component) {
        for (let groupNum = 0; groupNum < component.length; groupNum++) {
            items.push({ type: 'group', value: component[groupNum].flatData.groupName, id: ('g' + groupNum), originalId: groupNum })
            for (let num = 0; num < component[groupNum].referencingFaqContents.length; num++) {
                items.push({ type: 'question', value: component[groupNum].referencingFaqContents[num].flatData.faqQuestion, id: ('g' + groupNum + 'q' + num), originalId: num ,parentId :groupNum})
                items.push({ type: 'answer', value: component[groupNum].referencingFaqContents[num].flatData.faqLongAnswer, id: ('g' + groupNum + 'a' + num), originalId: num ,parentId :groupNum})

            }

        }
    }


    function boldString(str, find, id, group) {
        var startIndex = 0;
        str = str.replace(/<[^>]+>/g, '');
        str = he.decode(str);
        var reg = new RegExp('(' + find + ')', 'gi');
        
        // str= str.substring(0,10);
        if ((str.toLowerCase().indexOf(find.toLowerCase()) - 30) >= 0) {
            startIndex = str.toLowerCase().indexOf(find.toLowerCase()) - 30;
        }
        else {
            startIndex = 0;
        }

        if (str.length > 100) {
            if (startIndex >= 0) {

                str = str.substr((startIndex), 100);
                str = str.substr(str.indexOf(' '), 100);
                str = str.substr(0, str.lastIndexOf(' '));

            }
        }


        str = str.replace(reg, '<em>$1</em>');
        

        return str
    }

    
    return (
        <Downshift
            onChange={selection =>
                sendData({ type: selection.type, id: selection.id, originalId: selection.originalId, parentId: selection.parentId })
            }
            itemToString={item => (item ? he.decode(item.value.replace(/<[^>]+>/g, '')) : '')}
        >
            {({
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                isOpen,
                inputValue,
                highlightedIndex,
                selectedItem,
                getRootProps,
            }) => (
                <div className="help-support ">
                    <div
                        style={{ display: 'inline-block' }}
                        {...getRootProps({}, { suppressRefError: true })}
                    >
                        <input {...getInputProps()} placeholder="Type your keywords in here" />
                    </div>
                    <ul className="faqAutoCompleteUl" {...getMenuProps()}>
                        {isOpen
                            ? items
                                .filter(item => !inputValue || item.value.replace(/<[^>]+>/g, '').toLowerCase().includes(inputValue.toLowerCase()))
                                .map((item, index) => (
                                    <li
                                        {...getItemProps({
                                            key: item.id,
                                            index,
                                            item,
                                            style: {
                                                backgroundColor:
                                                highlightedIndex === index ? 'lightgrey' : 'white',
                                                fontWeight: selectedItem === item ? 'bold' : 'normal',
                                            },
                                        })}
                                    >

                                        <div dangerouslySetInnerHTML={{ __html: boldString(item.value, inputValue, item.id,item.type) }}></div>
                                    </li>
                                ))
                            : null}
                    </ul>
                </div>
            )}
        </Downshift>

    )
}

export default AutoComplete;