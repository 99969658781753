import React, { Fragment, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import Banner from '../components/pageComponents/Slider';
import BundlePricingComponent from '../components/pageComponents/BundlePricingComponent';
import LeftImageCard from '../components/pageComponents/LeftImageCard';
import FaqComponent from '../components/pageComponents/FaqComponent';
import CardRows from '../components/pageComponents/CardRows';
import ButtonOnlyComponent from '../components/pageComponents/ButtonOnlyComponent';
import BrandCardsComponent from '../components/pageComponents/BrandCardsComponent';
import ImageWithBenefitsComponent from '../components/pageComponents/ImageWithBenefitsComponent';
import PhysicalDevicesComponent from '../components/pageComponents/PhysicalDevicesComponent';
import FullFaqComponent from '../components/pageComponents/FullFaqComponent';
import SimpleComponent from '../components/pageComponents/SimpleComponent';
import InternationalCallRates from '../components/pageComponents/InternationalCallRates';
import axios from 'axios';
import { PAGE_LOOKUP } from '../GraphQl/Queries'
import GoogleMapsComponent from '../components/pageComponents/GoogleMapsComponent';


const ls = require('localstorage-ttl');


function Generic(props) {
  var { pageName } = useParams();

  if (!pageName) { pageName = "" }

    

  const [data, setData] = useState(null);

  useEffect(() => {
    var title = "K'nect Mobile"
    if(pageName)
    {
      document.title =pageName;
    }
    else
    {
      document.title = title;
    }
    var dbPageId = null;

    var allPages = props.data;


    for (let num = 0; num < allPages.length; num++) {
      if (('/' + pageName) === allPages[num].flatData.urlRoute) {
        dbPageId = allPages[num].id
      }

    }

    //page not found set to not found 
    if (!dbPageId) {
      for (let num = 0; num < allPages.length; num++) {
        if (allPages[num].flatData.urlRoute === "/not-found") {
          dbPageId = allPages[num].id;
        }
      }

    }

    var cache = ls.get("page:" + pageName);
    if (cache) {
      setData(cache);
    }
    else {
      setData(null);
      let query = PAGE_LOOKUP(dbPageId);
      console.log('query')
      console.log(query)

      axios({
        url: 'https://cms.computicket.com/api/content/knect/graphql',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
        data: {
          query: query
        }
      }).then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.data) {
              if (result.data.data.findPageLayoutContent.flatData.addcontent) {
                setData(result.data.data);
                if(result.data.data.findPageLayoutContent.flatData.seoJson)
                {
                  const seoScript = document.getElementById('seoScript');
                  seoScript.innerText = data.findPageLayoutContent.flatData.seoJson;
                }
                window.scrollTo(0, 0)

                ls.set("page:" + pageName, result.data.data, [30000]);
              }
            }
          }
        }

      })
        .catch(function (error) {
          // handle error
          setData(null);
        });
    }

  }, [pageName,props.data]);


  var pageData = [];
  if (data) {
    var newData = data.findPageLayoutContent.flatData.addcontent;
    pageData = newData;

    return (

      <Fragment>
        {pageData.map((item, index) => {
          if (item.__typename === 'Slider') {
            return (
              <Banner key={index} data={item} />
            )
          }
          else if (item.__typename === 'ImageText2Columns') {
            return (
              <LeftImageCard key={index} data={item} />
            )
          }
          else if (item.__typename === 'CardRows') {
            return (
              <CardRows key={index} data={item} />
            )
          }
          else if (item.__typename === 'ImageWithBenefits') {
            return (
              <ImageWithBenefitsComponent key={index} data={item} />
            )
          }
          else if (item.__typename === 'Button') {
            return (
              <ButtonOnlyComponent key={index} data={item} />
            )
          }
          else if (item.__typename === 'FullPageImageCards') {
            return (
              <BrandCardsComponent key={index} data={item} />
            )
          }
          else if (item.__typename === 'VirtualProductGroupComponent2') {
            return (
              <BundlePricingComponent key={index} data={item} />
            )
          }
          else if (item.__typename === 'FaqSummary') {
            return (
              <FaqComponent key={index} data={item} />
            )
          }
          else if (item.__typename === 'FaqFull') {
            return (
              <FullFaqComponent key={index} data={item} />
            )
          }

          else if (item.__typename === 'LinkPhysicalProducts') {
            return (
              <PhysicalDevicesComponent key={index} data={item} />
            )
          }
          else if (item.__typename === 'SimpleComponent') {
            return (
              <SimpleComponent key={index} data={item} />
            )
          }
          else if (item.__typename === 'InternationalCallRates') {

            return (
              <InternationalCallRates key={index} data={item} />
            )
          }
          else if (item.__typename === 'GoogleMapsComponent') {
            return (
              <GoogleMapsComponent key={index} data={item} />
            )
          }
          else {
            return ("");
          }
        }
        )}

      </Fragment>
    );

  }
  else {
    return ('')
  }






};

export default Generic;