import React, { Fragment } from 'react'
import './BrandCardsComponent.css';

function BrandCardsComponent(props) {

    let component = props.data.flatData
    return (
        <section className="page-layout-component" style={{ backgroundColor: component.backgroundColor && component.backgroundColor.length > 0 ? component.backgroundColor[0].flatData.colorValue : '' }}>
            <section className="brandCardsSection" style={{marginTop : component.marginTopOverride ? component.marginTopOverride: '' , marginBottom : component.marginBottomOverride ? component.marginBottomOverride : ''}}>
                <div className="fullwidth">
                    <h1 style={{ display: component.header ? '' : 'none', color: component.headerColor && component.headerColor.length > 0 ? component.headerColor[0].flatData.colorValue : '' }}>{component.header}</h1>
                    <div style={{ display: component.paragraph ? '' : 'none', color: 'white' , fontWeight : component.paragraphFontWeight ? component.paragraphFontWeight:'' , fontSize : component.paragraphFontSize ? component.paragraphFontSize:''}} dangerouslySetInnerHTML={{ __html: component.paragraph }}></div>
                </div>
                {component.imageRows.map((imageRow, imageRowIndex) => {
                    return (
                        <Fragment key={imageRowIndex}>


                            {imageRow.imageRowsSelection.map((imageRowsSelection, imageRowsSelectionIndex) => {


                                return (
                                    <section key={imageRowIndex + imageRowsSelectionIndex} className="brandcardrow">
                                        {imageRowsSelection.flatData.image.map((imageData, imageDataIndex) => {
                                            if (imageRowsSelection.flatData.image.length === 1) {
                                                return (
                                                    <div key={'image' + imageDataIndex} className="brandlastrow">
                                                        <div>
                                                            <a target="_blank" rel="noreferrer" href={imageData.flatData.linkUrl} alt={imageData.flatData.internalDescription}>
                                                                <img src={imageData.flatData.image && imageData.flatData.image.length > 0 ? imageData.flatData.image[0].url.slice(0, -1) : ''} alt={imageData.flatData.internalDescription}/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            else if (imageRowsSelection.flatData.image.length === 2) {
                                                return (
                                                    <div key={'image' + imageDataIndex} className="brand2ColumnsRow">
                                                        <div>
                                                            <a target="_blank" rel="noreferrer" href={imageData.flatData.linkUrl} alt={imageData.flatData.internalDescription}>
                                                                <img src={imageData.flatData.image && imageData.flatData.image.length > 0 ? imageData.flatData.image[0].url.slice(0, -1) : ''} alt={imageData.flatData.internalDescription}/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            else {

                                                return (
                                                    <div key={'card' + imageDataIndex} className="brandcard" style={{  boxShadow : imageRowsSelection && imageRowsSelection.flatData && imageRowsSelection.flatData.boxShadow ? imageRowsSelection.flatData.boxShadow : '' }}>
                                                        <a target="_blank" rel="noreferrer" href={imageData.flatData.linkUrl} alt={imageData.flatData.internalDescription} >
                                                            <img src={imageData.flatData.image && imageData.flatData.image.length > 0 ? imageData.flatData.image[0].url.slice(0, -1) : ''} alt={imageData.flatData.internalDescription} />
                                                        </a>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </section>
                                )


                            })}
                        </Fragment>

                    )
                })}

            </section>
        </section >
    )
}

export default BrandCardsComponent;