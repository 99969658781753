import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

function Global(props) {

    if (props.data) {
        let siteData = props.data.findSiteContent.flatData;
        let favicon = "";
        if (siteData.siteFavicon) {
            favicon = siteData.siteFavicon[0].url;
        }

        return (
            <HelmetProvider>
                <Helmet>
                    <style type="text/css">{`
            :root {
                --primary-color: ${siteData.sitePrimaryColor[0].flatData.colorValue};
                --secondary-color: ${siteData.siteSecondaryColor[0].flatData.colorValue};
                --third-color: ${siteData.siteThirdColor[0].flatData.colorValue};
                --primary-font-color: ${siteData.siteTextColorMain[0].flatData.colorValue};
                --secodary-font-color: ${siteData.siteTextColorSecondary[0].flatData.colorValue};
                --primary_font_family: ${siteData.sitePrimaryFont[0].flatData.fontFamily};
                --secodary_font_family: ${siteData.siteSecondaryFont[0].flatData.fontFamily};
                --screen-padding: 60%;
                --component-margin: 40px auto;
                --component-padding: ${siteData.componentPadding}
                `}
                    </style>
                    <link rel="icon" href={favicon} />
                    <title>Knect Mobile</title>
                    <link href={siteData.sitePrimaryFont[0].flatData.fontFamilyUrl} rel="stylesheet"></link>
                    <link href={siteData.siteSecondaryFont[0].flatData.fontFamilyUrl} rel="stylesheet"></link>
                    <meta name="description" content={siteData.siteMetaDescription} />
                    <meta name="keywords" content={siteData.siteMetaTags} />
                </Helmet>
            </HelmetProvider>
        );
    }
    else {
        return ('no data yet')
    }
}


export default Global;