export const PAGE_LOOKUP = (dbPageId) => `{
  findPageLayoutContent(id: "${dbPageId}") {
    flatData {
      seoJson
      addcontent {
        __typename
        ... on Slider {
          flatData {
            sliders {
              flatData {
                buttonToTextAligment
                buttonToTextAlignmentMobile
                buttonColor {
                  flatData {
                    colorValue
                  }
                }
                buttonText
                buttonTextColor {
                  flatData {
                    colorValue
                  }
                }
                customCss
                desktop {
                  url
                }
                desktopBottomPosition
                desktopLeftPosition
                externalLink
                internalLink {
                  flatData {
                    urlRoute
                  }
                }
                isExternal
                mobile {
                  url
                }
                mobileBottomPosition
                mobileLeftPosition
                textUnderButton
                textUnderButtonColor {
                  flatData {
                    colorValue
                  }
                }
              }
            }
          }
        }
        ... on CardRows {
          id
          flatData {
            backgroundColor {
              flatData {
                colorValue
              }
            }
            cards {
              flatData {
                cardImage {
                  url
                }
                cardImageBackgroundColor {
                  flatData {
                    colorValue
                  }
                }
                cardMessage
                cardMessageBackgroundColor {
                  flatData {
                    colorValue
                  }
                }
                ctaText
                ctaTextColor {
                  flatData {
                    colorValue
                  }
                }
                externalLink
                externalUrl
                headerText
                internalLink {
                  id
                  flatData {
                    urlRoute
                  }
                }
                isButton
                textColor {
                  flatData {
                    colorValue
                  }
                }
              }
            }
            cardsHeadingFontSize
            heading
            headingColor {
              flatData {
                colorValue
              }
            }
            imageHeight
            imageWidth
            subHeading
            typesOfCards {
              flatData {
                cardTypeIdentifier
              }
            }
          }
        }
        ... on ImageText2Columns {
          flatData {
            additionalContentAlignment
            textColumnWidth
            imageColumnWidth
            marginOverride
            imageSize
            header
            imagePosition
            paragraph
            subHeading
            gapSize
            gapSizeMobile
            shiftImage
            imageMoveTop
            imageMoveLeft
            imageMaxWidth
            headerTextColor {
              flatData {
                colorValue
              }
            }
            additionalContent {
              button {
                flatData {
                  backgroundColor {
                    flatData {
                      colorValue
                    }
                  }
                  buttonText
                  secondaryText
                  externalLink
                  internalLink {
                    id
                    flatData {
                      urlRoute
                    }
                  }
                  isExternal
                  position
                  topMargin
                }
              }
              imageRow {
                flatData {
                  imageWidth
                  justifyImages
                  image {
                    flatData {
                      internalDescription
                      linkUrl
                      image {
                        url
                      }
                    }
                  }
                  noOfImagesInRow
                  boxShadow
                }
              }
            }
            backgroundColor {
              flatData {
                colorValue
              }
            }
            image {
              url
            }
            textColor {
              flatData {
                colorValue
              }
            }
          }
        }
        ... on Button {
          flatData {
            backgroundColor {
              flatData {
                colorValue
              }
            }
            buttonText
            secondaryText
            externalLink
            internalLink {
              id
              flatData {
                urlRoute
              }
            }
            isExternal
            position
            topMargin
          }
        }
        ... on ImageWithBenefits {
          flatData {
            heading
            subHeading
            benefits {
              flatData {
                details
                heading
                summary
              }
            }
            backgroundColor {
              flatData {
                colorValue
              }
            }
            bottomLeftImage {
              url
            }
            bottomRightImage {
              url
            }
            mainImage {
              url
            }
            topLeftImage {
              url
            }
            topRightImage {
              url
            }
          }
        }
        ... on FullPageImageCards {
          flatData {
            header
            subHeading
            paragraph
            marginBottomOverride
            marginTopOverride
            paragraphFontWeight
            paragraphFontSize
            backgroundColor {
              flatData {
                colorValue
              }
            }
            headerColor {
              flatData {
                colorValue
              }
            }
            imageRows {
              imageRowsSelection {
                flatData {
                  image {
                    flatData {
                      internalDescription
                      linkUrl
                      image {
                        url
                      }
                    }
                  }
                  noOfImagesInRow
                  boxShadow
                }
              }
            }
            paragraphColor {
              flatData {
                colorValue
              }
            }
            subHeadingColor {
              flatData {
                colorValue
              }
            }
          }
        }
        ... on FaqSummary {
          flatData {
            contactDetials {
              flatData {
                email
                operatingHours
                telNo
              }
            }
            backgroundColor {
              flatData {
                colorValue
              }
            }
            faq {
              flatData {
                faqQuestion
                faqShortAnswer
              }
            }
          }
        }
        ... on VirtualProductGroupComponent2 {
          flatData {
            header
            
            subHeader
            topLeftImage {
              url
            }
            topRightImage {
              url
            }
            botLeftImage {
              url
            }
            botRightImage {
              url
            }
            textColor {
              flatData {
                colorValue
              }
            }
            backgroundColor {
              flatData {
                colorValue
              }
            }
            virtualProductGroups {
              flatData {
                newPriceDisplay
                description
                info
                standardRates{
                  topText
                  logo{
                    url
                  }
                  linkedProducts {
                  flatData {
                    displayText
                    specialInfo
                    normalPrice
                    totalBenefitDescription
                    totalBenefitDisplay
                    allBenefitsIncludedValue
                    linkedBenefits {
                      flatData {
                        textDisplay
                        benefit {
                          flatData {
                            displayText
                            displayMessage
                            image {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
                  bottomImage{
                    url
                  }
                  bottomText
                  backgroundColor{
                    flatData
                    {
                      colorName
                      colorValue
                    }
                  }
                  textColor
                  {
                    flatData
                    {
                      colorName
                      colorValue
                    }
                  }
                  
                }
                specialPrices
                {
                  topText
                  logo
                  {
                    url
                  }
                  callToAction
                  linkedItems {
                  flatData {
                    displayText
                    specialInfo
                    normalPrice
                    totalBenefitDescription
                    totalBenefitDisplay
                    allBenefitsIncludedValue
                    linkedBenefits {
                      flatData {
                        textDisplay
                        benefit {
                          flatData {
                            displayText
                            displayMessage
                            image {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
                  button
                  {
                    flatData
                    {
                      buttonText
                      secondaryText
                      isExternal
                      externalLink
                      position
                      backgroundColor{
                        flatData
                        {
                          colorName
                          colorValue
                        }
                      }
                      topMargin
                      internalLink
                      {
                        flatData
                        {
                          pageId
                          urlRoute
                          seoJson
                        }
                      }
                      secondaryText
                    }
                    
                  }
                  backgroundColor
                  {
                    flatData
                    {
                      colorName
                      colorValue
                    }
                  }
                  ctaColor{
                    flatData
                    {
                      colorName
                      colorValue
                    }
                  }
                  textColor{
                    flatData
                    {
                      colorName
                      colorValue
                    }
                  }
                }
                linkedProducts {
                  flatData {
                    displayText
                    specialInfo
                    normalPrice
                    totalBenefitDescription
                    totalBenefitDisplay
                    allBenefitsIncludedValue
                    linkedBenefits {
                      flatData {
                        textDisplay
                        benefit {
                          flatData {
                            displayText
                            displayMessage
                            image {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on FaqFull {
          flatData {
            topLeftImage {
              url
            }
            topRightImage {
              url
            }
            botLeftImage {
              url
            }
            botRightImage {
              url
            }
            header
            subHeading
            headerColor {
              flatData {
                colorValue
              }
            }
            backgroundImage {
              url
            }
            faqGroups {
              flatData {
                groupName
              }
              referencingFaqContents {
                flatData {
                  faqLongAnswer
                  faqQuestion
                }
              }
            }
          }
        }
        ... on LinkPhysicalProducts {
          flatData {
            header
            headerColor {
              flatData {
                colorValue
              }
            }
            subHeading
            actualProducts {
              flatData {
                savingsMessage
                savingsMessageColor{
                flatData {
                  colorValue
                }
              }
                cashPrice
                extraDetail {
                  extraDetailsDetail
                  extraDetailsHeading
                }
                largeImage {
                  url
                }
                additionalDetailsHeading
                longDescription
                payOffline
                priceLimitation
                productDisplayName
                productType
                specs
                thumbnailImage {
                  url
                }
                url
              }
            }
          }
        }
        ... on SimpleComponent {
          flatData {
            headingMargin
            subHeadingMargin
            paragraphMargin
            customhtml
            backgroundColor {
              flatData {
                colorValue
              }
            }
            headerColor {
              flatData {
                colorValue
              }
            }
            heading
            paragraph
            paragraphColor {
              flatData {
                colorValue
              }
            }
            subHeading
            subHeadingColor {
              flatData {
                colorValue
              }
            }
          }
        }
        ... on InternationalCallRates {
          flatData {
            backgroundColor {
              flatData {
                colorValue
              }
            }
            header
            headerColor {
              flatData {
                colorValue
              }
            }
            paragraph
          }
        }
        ... on GoogleMapsComponent {
          id
        }
      }
    }
  }
}
`

//4c7eb359-dd5c-4ab7-b7b7-c1baea59435b

export const SITE_CONFIG = () => `{
  findSiteContent(id: "f781c3ff-4bb3-451b-a5d6-adcee19462c1") {
    flatData {
      siteId
      siteDescription
      siteFavicon {
        url
      }
      siteGtm
      siteMetaDescription
      siteMetaTags
      siteMetaTitle
      sitePrimaryColor {
        flatData {
          colorValue
        }
      }
      sitePrimaryFont {
        flatData {
          fontFamily
          fontFamilyUrl
        }
      }
      siteSecondaryColor {
        flatData {
          colorValue
        }
      }
      siteSecondaryFont {
        flatData {
          fontFamily
          fontFamilyUrl
        }
      }
      siteTextColorMain {
        flatData {
          colorValue
        }
      }
      siteTextColorSecondary {
        flatData {
          colorValue
        }
      }
      siteThirdColor {
        flatData {
          colorValue
        }
      }
      componentPadding
      sitePages{
        ... on PageLayout
        {
          id
          flatData
          {
            urlRoute
          }
        }
      }
    }
    
  }
}`

export const MENU_ITEMS = () => `{
  findSiteContent(id: "f781c3ff-4bb3-451b-a5d6-adcee19462c1") {
    flatData {
      siteMenu {
        flatData {
          leftLogo {
            url
          }
          menuDescription
          overideTextColor {
            flatData {
              colorValue
            }
          }
          overrideBackgroundColor {
            flatData {
              colorValue
            }
          }
          rightLogo {
            url
          }
          menuItems {
            flatData {
              menuItemDescription
              hasSubItems
              subMenuItems
              {
                flatData
                {
                  description
                  exsternalLink
                  isExsternal
                  pageLayout
                  {
                    flatData
                    {
                      urlRoute
                    }
                  }
                }
              }
              pageLayout {
                id
                flatData {
                  pageId
                  urlRoute
                }
              }
            }
          }
        }
      }
    }
  }
}`

export const DEVICE_LOOKUP = (pageName, device) => `
{
  queryPhysicalProductContents(filter: "data/url/iv eq '${device}'")
   {
      flatData
     {
      savingsMessage
      savingsMessageColor{
      flatData {
        colorValue
      }
    }
       additionalDetailsHeading
       cashPrice
       extraDetail
       {
         extraDetailsDetail
         extraDetailsHeading
       }
       largeImage
       {
         url
       }
       longDescription
       payOffline
       priceLimitation
       productDisplayName
       productType
       specs
       thumbnailImage
       {
         url
       }
       url
     }
   }
 }
`

export const FOOTER_LOOKUP = () => `{
  findSiteContent(id: "f781c3ff-4bb3-451b-a5d6-adcee19462c1") {
    flatData {
      siteId
      footer {
        ... on gql_4Column {
          __typename
          flatData {
            columns {
              flatData {
                header
                columnItems {
                  ... on WordsAndLinks {
                    __typename
                    flatData {
                      externalLink
                      internalLink {
                        flatData {
                          urlRoute
                        }
                      }
                      isExternal
                      words
                    }
                  }
                  ... on ImageRow{
                    __typename
                    flatData
                    {
                      imageWidth
                      justifyImages
                      lineAbove
                      textAbove
                      image
                      {
                        flatData
                        {
                          internalDescription
                          linkUrl
                          image
                          {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on ImageTextRow {
          __typename
          flatData {
            header
            linkedContent {
              flatData {
                externalLink
                image {
                  url
                }
                internalLink {
                  flatData {
                    urlRoute
                  }
                }
                isExternal
                text
              }
            }
          }
        }
        ... on TextLinkRow {
          __typename
          flatData {
            linkedContent {
              flatData {
                externalLink
                internalLink {
                  flatData {
                    urlRoute
                  }
                }
                isExternal
                words
              }
            }
          }
        }
        ... on ImageRow {
          __typename
          flatData {
            image {
              flatData {
                internalDescription
                linkUrl
                image {
                  url
                }
              }
            }
            imageWidth
            justifyImages
            lineAbove
            noOfImagesInRow
            boxShadow
            textAbove
          }
        }
      }
    }
  }
}`

export const CALL_RATES_LOOKUP = (test) => `{
  queryRatesContents(orderby : "data/countryname/iv") {
    flatData {
      alias
      alpha2
      alpha3
      countrycode
      countryname
      maxRate
    }
  }
}`


