import React from 'react'
import { Link } from 'react-router-dom';
import './ButtonOnlyComponent.css';

function ButtonOnlyComponent(props) {

    let buttonComponent = props.data.flatData;


    var buttonPosition = 'center';
    if (buttonComponent.position === 'left') {
        buttonPosition = 'flex-start';
    }
    else if (props.data.position === 'right') {
        buttonComponent.position = 'flex-end';

    }

    return (
        <section className="page-layout-component" style={{ backgroundColor: (buttonComponent.backgroundColor && buttonComponent.backgroundColor.length > 0  ? buttonComponent.backgroundColor[0].flatData.colorValue : '')}}>
            <section className="buttononly" style={{ alignItems: buttonPosition, marginTop: buttonComponent.topMargin }}>
                <div className="cta_button" >
                    {buttonComponent.isExternal ? <a target="_blank" rel="noreferrer" style={{ display: buttonComponent.isExternal ? '' : 'none' }} href={buttonComponent.externalLink}>{buttonComponent.buttonText}</a>: <Link style={{ display: buttonComponent.isExternal ? 'none' : '' }} to={buttonComponent.internalLink && buttonComponent.internalLink.length > 0 ? buttonComponent.internalLink[0].flatData.urlRoute : '/'} >{buttonComponent.buttonText}</Link>}
                </div>
            </section>
        </section>
    )
}

export default ButtonOnlyComponent;