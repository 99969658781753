import React, { useState } from 'react'
import './FullFaqComponent.css';
import AutoComplete from './AutoComplete';

function FullFaqComponent(props) {
    const [activeaccordian, setActiveaccordian] = useState(null);
    const [activeChiledAccordian, setactiveChiledAccordian] = useState(null);

    const callbackFunction = (childData) => {
        if (childData) {
            if (childData.type === 'group') {
                setActiveaccordian(childData.originalId)
                let elmnt = document.getElementById('fullaccordian' + childData.originalId);
                if (elmnt) {
                    let coords = elmnt.getBoundingClientRect();
                    window.scrollTo(coords.x, (coords.y - 100))
                }

            }
            else {
                setActiveaccordian(childData.parentId)
                setactiveChiledAccordian(childData.originalId)

                let parent = document.getElementById('fullaccordian' + childData.parentId);
                if (parent) {
                    let coords = parent.getBoundingClientRect();
                    window.scrollTo(coords.x, (coords.y - 100))
                }

                // let elmnt = document.getElementById('childaccordian' + childData.parentId + childData.originalId);
                // if (elmnt) {
                //     let coords = elmnt.getBoundingClientRect();
                //     window.scrollTo(coords.x, (coords.y - 100))
                // }
            }
        }

    }

    var component = props.data.flatData

    function mainAccordianClick(index) {
        if (activeaccordian === index) {
            setActiveaccordian(null);
            setactiveChiledAccordian(null);
        }
        else {
            setActiveaccordian(index);
            setactiveChiledAccordian(null);


        }
    }

    function childAccordianClick(index) {
        if (activeChiledAccordian === index) {
            setactiveChiledAccordian(null);
        }
        else {
            setactiveChiledAccordian(index);

        }
    }


    return (
        <section className="page-layout-component faqbackgroundImage" style={{ backgroundImage: 'url(' + (component.backgroundImage ? component.backgroundImage[0].url.slice(0, -1) : '') + ')' }}>
            <img className="faqtopleftimg" src={(component.topLeftImage && component.topLeftImage.length > 0 ? component.topLeftImage[0].url.slice(0, -1) : '')} alt="" />
            <img className="faqtoprightimg" src={(component.topRightImage && component.topRightImage.length > 0 ? component.topRightImage[0].url.slice(0, -1) : '')} alt="" />
            <img className="faqbottomleftimg" src={(component.botLeftImage && component.botLeftImage.length > 0 ? component.botLeftImage[0].url.slice(0, -1) : '')} alt="" />
            <img className="faqbottomrightimg" src={(component.botRightImage && component.botRightImage.length > 0 ? component.botRightImage[0].url.slice(0, -1) : '')} alt="" />
            <div className="help-support" >

                <h1 style={{ color: (component.headerColor && component.headerColor.length > 0 ? component.headerColor[0].flatData.colorValue : '') }}>{component.header}</h1>
                <div className="faqFullsubHeading" dangerouslySetInnerHTML={{ __html: component.subHeading }}></div>

                <AutoComplete data={component.faqGroups} parentCallback={callbackFunction} />
                <p style={{ textAlign: "center", maxWidth: '800px', margin: '10px auto 20px' }}>
                    Didn’t find what you’re looking for?<br />Maybe your answer is in one of these categories below?
			    </p>
                {component.faqGroups && component.faqGroups.map((item, index) => {
                    return (
                        <div key={index} className="faqfullaccordian ">
                            <div id={'fullaccordian' + index} className={'fullaccordian ' + (index === activeaccordian ? 'open' : 'close')}>
                                <div className="faqfullacchead" onClick={() => mainAccordianClick(index)}>
                                    <div className="faqfullhead">
                                        {item.flatData.groupName}
                                    </div>
                                </div>
                                <div className="faqMaindetail" >
                                    {item.referencingFaqContents && item.referencingFaqContents.map((faqItem, faqIndex) => {
                                        return (
                                            <div id={'faqChild' + faqIndex} key={faqIndex} className="faqChildContainer">
                                                <div id={'childaccordian' + index + faqIndex} key={faqIndex} className={'faqchildaccordionitem ' + (faqIndex === activeChiledAccordian ? 'openedchild' : 'closedchild')} style={{ marginTop: faqIndex === 0 ? '-10px' : '' }}>
                                                    <hr className="helpLine" />
                                                    <h3 className="accordionItemHeading" onClick={() => childAccordianClick(faqIndex)}>{faqItem.flatData.faqQuestion}</h3>
                                                    <div className="faqchilsaccordionItemContent">
                                                        <div dangerouslySetInnerHTML={{ __html: faqItem.flatData.faqLongAnswer }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })}
                                    <hr className="helpLine" />

                                </div>
                            </div>
                        </div>
                    )
                })}
                {/* <img className="help-support-img" src= alt="" /> */}
            </div>
        </section>
    )
}

export default FullFaqComponent;