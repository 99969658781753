import React, { useState } from 'react';
import './ImageWithBenefitsComponent.css';

function ImageWithBenefitsComponent(props) {
    const [activeaccordian, setActiveaccordian] = useState(null);
    function handleClick(index) {
        if (activeaccordian === index) {
            setActiveaccordian(null);
        }
        else {
            setActiveaccordian(index);

        }
    }



    var component = props.data.flatData;
    return (
        <section className="page-layout-component" style={{ backgroundColor: component.backgroundColor && component.backgroundColor.length > 0 ? component.backgroundColor[0].flatData.colorValue : '' }}>
            <section className="imagebenefits" style={{ backgroundImage: 'url(' + (component.mainImage && component.mainImage.length > 0 ? component.mainImage[0].url.slice(0, -1) : '') + ')' }}>
                <img className="topleftimg" src={(component.topLeftImage && component.topLeftImage.length > 0 ? component.topLeftImage[0].url.slice(0, -1) : '')} alt="" />
                <img className="toprightimg" src={(component.topRightImage && component.topRightImage.length > 0 ? component.topRightImage[0].url.slice(0, -1) : '')} alt="" />
                <img className="bottomleftimg" src={(component.bottomLeftImage && component.bottomLeftImage.length > 0 ? component.bottomLeftImage[0].url.slice(0, -1) : '')} alt="" />
                <img className="bottomrightimg" src={(component.bottomRightImage && component.bottomRightImage.length > 0 ? component.bottomRightImage[0].url.slice(0, -1) : '')} alt="" />
                <div className="rightbenefittcolum">
                    <div className="imagebenefitsheader">
                        <h1>
                            {component.heading}
                        </h1>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: component.subHeading }}>
                    </div>
                    <div className="benefitsaccordian ">

                        {component.benefits.map((item, index) => {
                            return (
                                <div key={index} className={'accordionItem ' + (index === activeaccordian ? 'open' : 'close')}>
                                    <div className="benefitacchead" onClick={() => handleClick(index)}>
                                        <div className="benhead">{item.flatData.heading}</div>
                                        <div className="morebenefit">VIEW MORE<div className="benarrow"></div></div>
                                    </div>
                                    <div className="benefitdetail" dangerouslySetInnerHTML={{ __html: item.flatData.details }}>
                                    </div>
                                    <div className="benefitextra" dangerouslySetInnerHTML={{ __html: item.flatData.summary }}></div>
                                </div>

                            )
                        })}
                    </div>


                </div>

            </section>
        </section>
    )
}

export default ImageWithBenefitsComponent;