import React, { Fragment } from "react";
import { Link } from 'react-router-dom'
import './Slider.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { useMediaQuery } from 'react-responsive'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function Banner(props) {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    var component = props.data.flatData.sliders;

    if (isTabletOrMobile) {
        return (
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={0}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                speed={600}
                autoplay={{ delay: 4000 }}>

                {component && component.map((item, index) => (
                    <SwiperSlide key={index}>
                        {item.flatData && item.flatData.mobile && item.flatData.mobile.map((imageItem, imageIndex) => (
                            <Fragment key={imageIndex}>
                                <img key={index} className="sliderImage" srcSet={imageItem.url.slice(0, -1)} alt="" />
                                <div className="sliderBtnContainer" style={{ display: item.flatData.buttonText ? "" : "none", bottom: item.flatData.mobileBottomPosition, left: item.flatData.mobileLeftPosition, textAlign: item.flatData.buttonToTextAlignmentMobile }}>
                                    {item.flatData.isExternal ? 
                                        <a href={item.flatData.externalLink} style={{ display: item.flatData.isExternal ? '' : 'none', backgroundColor: item.flatData.buttonColor && item.flatData.buttonColor.length > 0 ?item.flatData.buttonColor[0].flatData.colorValue : '', color: item.flatData.buttonTextColor && item.flatData.buttonTextColor.length > 0 ?item.flatData.buttonTextColor[0].flatData.colorValue: '' }} className="sliderDesktopBtn">{item.flatData.buttonText}</a> 
                                        : 
                                        <Link to={item.flatData.internalLink && item.flatData.internalLink.length > 0 ? item.flatData.internalLink[0].flatData.urlRoute : '/'} style={{ display: item.flatData.isExternal ? 'none' : '', backgroundColor: item.flatData.buttonColor && item.flatData.buttonColor.length > 0 ? item.flatData.buttonColor[0].flatData.colorValue : '', color: item.flatData.buttonTextColor && item.flatData.buttonTextColor.length > 0 ? item.flatData.buttonTextColor[0].flatData.colorValue : ''}} className="sliderDesktopBtn">{item.flatData.buttonText}</Link>
                                    }
                                    <div style={{ color: item.flatData.textUnderButtonColor && item.flatData.textUnderButtonColor.length > 0 ? item.flatData.textUnderButtonColor[0].flatData.colorValue : ''}} className="slderTextUnder" dangerouslySetInnerHTML={{ __html: item.flatData.textUnderButton }}></div>
                                </div>
                            </Fragment>
                        ))}
                    </SwiperSlide>
                ))}
            </Swiper>
        )
    }
    else {
        return (
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={0}
                slidesPerView={1}
                navigation
                speed={600}
                pagination={{ clickable: true }}
                autoplay={{ delay: 4000 }}>

                {component && component.map((item, index) => (
                    <SwiperSlide key={index}>
                        {item.flatData && item.flatData.desktop && item.flatData.desktop.map((imageItem, imageIndex) => (
                            <Fragment key={imageIndex}>
                                <img key={index} className="sliderImage" srcSet={imageItem.url.slice(0, -1)} alt="" />
                                <div className="sliderBtnContainer" style={{display: item.flatData.buttonText ? "" : "none", bottom: item.flatData.desktopBottomPosition, left: item.flatData.desktopLeftPosition, textAlign: item.flatData.buttonToTextAligment }}>
                                    {item.flatData.isExternal ? 
                                        <a href={item.flatData.externalLink} style={{ display: item.flatData.isExternal ? '' : 'none', backgroundColor: item.flatData.buttonColor && item.flatData.buttonColor.length > 0 ? item.flatData.buttonColor[0].flatData.colorValue : '', color: item.flatData.buttonTextColor && item.flatData.buttonTextColor.length > 0 ? item.flatData.buttonTextColor[0].flatData.colorValue : '' }} className="sliderDesktopBtn">{item.flatData.buttonText}</a> 
                                        : 
                                        <Link to={item.flatData.internalLink && item.flatData.internalLink.length > 0 ? item.flatData.internalLink[0].flatData.urlRoute : '/'} style={{ display: item.flatData.isExternal ? 'none' : '', backgroundColor: item.flatData.buttonColor && item.flatData.buttonColor.length > 0 ? item.flatData.buttonColor[0].flatData.colorValue : '', color: item.flatData.buttonTextColor && item.flatData.buttonTextColor.length > 0 ? item.flatData.buttonTextColor[0].flatData.colorValue : ''}} className="sliderDesktopBtn">{item.flatData.buttonText}</Link>
                                    }
                                    <div style={{ color: item.flatData.textUnderButtonColor && item.flatData.textUnderButtonColor.length > 0 ? item.flatData.textUnderButtonColor[0].flatData.colorValue  : ''}} className="slderTextUnder" dangerouslySetInnerHTML={{ __html: item.flatData.textUnderButton }}></div>
                                </div>
                            </Fragment>
                        ))}
                    </SwiperSlide>
                ))}
            </Swiper>
        )
    }
}

export default Banner;