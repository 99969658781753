import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './CardRows.css';

function CardRows(props) {


    const [headerHight, setdHeaderHight] = useState(0);
    const [ctaHight, setCtaHight] = useState(0);



    let cardType = null;
    let cardInfo = props.data.flatData;
    if (cardInfo) {
        cardType = cardInfo.typesOfCards[0].flatData.cardTypeIdentifier;
    }

    useEffect(() => {

        var newHeight = 0;
        var ctaNewHeight = 0
        for (let num = 0; num < cardInfo.cards.length; num++) {
            if (document.getElementById('cardHeaderL' + props.data.id + num)) {
                let height = document.getElementById('cardHeaderL' + props.data.id + num).clientHeight;
                if (height > newHeight) {
                    newHeight = height;
                }
            }

            //cta height 
            if (document.getElementById('cardCta' + props.data.id + num)) {
                let height = document.getElementById('cardCta' + props.data.id + num).clientHeight;
                if (height > ctaNewHeight) {
                    ctaNewHeight = height;
                }
            }
        }
        setCtaHight(ctaNewHeight);
        setdHeaderHight(newHeight);

    }, [cardInfo.cards.length,props.data.id]);


    if (cardType === 'default') {
        return (

            <section className="page-layout-component" style={{ backgroundColor: (cardInfo.backgroundColor && cardInfo.backgroundColor.length > 0 ? cardInfo.backgroundColor[0].flatData.colorValue : '') }}>
                <section className="cardrow">
                    <div className="fullwidth">
                        <h1 style={{ display: cardInfo.heading ? '' : 'none', color: cardInfo.headingColor && cardInfo.headingColor.length > 0 ? cardInfo.headingColor[0].flatData.colorValue : '' }}>{cardInfo.heading}</h1>
                        <div dangerouslySetInnerHTML={{ __html: cardInfo.subHeading }} style={{ display: cardInfo.subHeading ? '' : 'none' }}></div>
                    </div>
                    {cardInfo.cards.map((item, index) => {
                        let cardWidth;
                        if (cardInfo.cards.length === 3) {
                            cardWidth = '235px';
                        }
                        return (
                            <div key={index} className="card" style={{ color: (item.flatData.textColor && item.flatData.textColor.length > 0 ? item.flatData.textColor[0].flatData.colorValue : ''), minWidth: cardWidth }}>
                                <div>
                                    <img style={{ width: cardInfo.imageWidth, height: cardInfo.imageHeight }} src={(item.flatData.cardImage && item.flatData.cardImage.length > 0 ? item.flatData.cardImage[0].url.slice(0, -1) : '')} alt="" />
                                </div>
                                <div className="cardcontainer">
                                    <div id={'cardHeaderL' + props.data.id + index} className={!item.flatData.headerText ? 'cardheader displaynone' : 'cardheader'} >{item.flatData.headerText}</div>
                                    <div className={!item.flatData.cardMessage ? 'cardtext displaynone' : 'cardtext'} dangerouslySetInnerHTML={{ __html: item.flatData.cardMessage }}></div>
                                    <div>
                                        <div id={'cardCta'+ props.data.id + index} className={item.flatData.isButton ? 'card_cta_button' : 'card_cta_text'} style={{ display: (!item.flatData.ctaText ? 'none' : '') }}>
                                            {item.flatData.externalLink ? <a  style={{ display: item.flatData.externalLink ? '' : 'none', color: (item.flatData.ctaTextColor && item.flatData.ctaTextColor.length > 0 ? item.flatData.ctaTextColor[0].flatData.colorValue : '') }} href={item.flatData.externalUrl ? item.flatData.externalUrl : '/'} >{item.flatData.ctaText}</a> : <Link  style={{ display: item.flatData.externalLink ? 'none' : '', color: (item.flatData.ctaTextColor && item.flatData.ctaTextColor > 0 ? item.flatData.ctaTextColor[0].flatData.colorValue : '') }} to={item.flatData.internalLink && item.flatData.internalLink.length > 0 ? item.flatData.internalLink[0].flatData.urlRoute : '/'}>{item.flatData.ctaText}</Link>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )

                    })}

                </section>
            </section>
        )
    }
    else {
        return (
            <section className="page-layout-component" style={{ backgroundColor: (cardInfo.backgroundColor && cardInfo.backgroundColor.length > 0 ? cardInfo.backgroundColor[0].flatData.colorValue : '') }}>
                <section className="cardrow">
                    <div className="fullwidth">
                        <h1 style={{ display: cardInfo.heading ? '' : 'none', color: cardInfo.headingColor && cardInfo.headingColor.length > 0 ? cardInfo.headingColor[0].flatData.colorValue : '' }}>{cardInfo.heading}</h1>
                        <div dangerouslySetInnerHTML={{ __html: cardInfo.subHeading }} style={{ display: cardInfo.subHeading ? '' : 'none' }}></div>
                    </div>
                    {cardInfo.cards.map((item, index) => {
                        let cardWidth;
                        if (cardInfo.cards.length === 3) {
                            cardWidth = '235px';
                        }

                        return (
                            
                            <div key={index} className="colorcard" style={{ minWidth: cardInfo.imageWidth ? cardInfo.imageWidth : cardWidth }}>
                                <div className="imagecontainer" style={{ backgroundColor: (item.flatData.cardImageBackgroundColor ? item.flatData.cardImageBackgroundColor[0].flatData.colorValue : '') }}>
                                    <img style={{ width: cardInfo.imageWidth, height: cardInfo.imageHeight }} src={(item.flatData.cardImage ? item.flatData.cardImage[0].url.slice(0, -1) : '')} alt="" />
                                </div>
                                <div className="colorcardcontainer" style={{ backgroundColor: item.card_message_background_color, color: item.text_color }}>
                                    <div id={'cardHeaderL' + props.data.id + index} className="colorcardheader" style={{ height: headerHight !== 0 ? headerHight : '' }}>{item.flatData.headerText} </div>
                                    <div className="colorcardtext" dangerouslySetInnerHTML={{ __html: item.flatData.cardMessage }}></div>
                                    <div id={'cardCta'+ props.data.id + index} className="colorcard_cta_text" style={{ display: (item.flatData.isButton ? '' : 'none'),height: ctaHight !== 0 ? ctaHight : '' }}>
                                        {item.flatData.externalLink ? <a  style={{ display: item.flatData.externalLink ? '' : 'none', color: (item.flatData.ctaTextColor && item.flatData.ctaTextColor.length > 0  ? item.flatData.ctaTextColor[0].flatData.colorValue : '') }} href={item.flatData.externalUrl} className="cardlink">{item.flatData.ctaText}</a> : <Link className="cardlink" style={{ display: item.flatData.externalLink ? 'none' : '', color: (item.flatData.ctaTextColor && item.flatData.ctaTextColor.length > 0 ? item.flatData.ctaTextColor[0].flatData.colorValue : '') }} to={item.flatData.internalLink && item.flatData.internalLink.length > 0 ? item.flatData.internalLink[0].flatData.urlRoute : '/'}>{item.flatData.ctaText}</Link>}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </section>
            </section>
        )
    }
}

export default CardRows;