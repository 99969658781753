import React from 'react'
import './SimpleComponent.css';

function SimpleComponent(props) {
    let component = props.data.flatData

    return (
        <section className="page-layout-component" style={{ backgroundColor: (component.backgroundColor && component.backgroundColor.length > 0 ? component.backgroundColor[0].flatData.colorValue : '') }}>
            <section className="simpleComponent">
                <div style={{ display: component.heading ? '' : 'none', margin: component.headingMargin }}>
                    <h1 style={{ color: (component.headerColor && component.headerColor.length > 0 ? component.headerColor[0].flatData.colorValue : '') }}>{component.heading}</h1>
                </div>
                <div style={{ display: component.subHeading ? '' : 'none', color: (component.subHeadingColor && component.subHeadingColor.length > 0 ? component.subHeadingColor[0].flatData.colorValue : ''), margin: component.subHeadingMargin }} dangerouslySetInnerHTML={{ __html: component.subHeading }}></div>
                <div style={{ display: component.paragraph ? '' : 'none', color: (component.paragraphColor && component.paragraphColor.length > 0 ? component.paragraphColor[0].flatData.colorValue : ''), margin: component.paragraphMargin }} className="paragraph" dangerouslySetInnerHTML={{ __html: component.paragraph }}></div>
                <div style={{ display: component.customhtml ? '' : 'none', color: (component.paragraphColor && component.paragraphColor.length > 0 ? component.paragraphColor[0].flatData.colorValue : ''), margin: component.paragraphMargin }} className="paragraph" dangerouslySetInnerHTML={{ __html: component.customhtml }}></div>

            </section>
        </section>
    )
}

export default SimpleComponent;