import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import './PhysicalDevicesComponent.css';
import axios from 'axios';
import { DEVICE_LOOKUP } from '../../GraphQl/Queries'

function PhysicalDevicesComponent(props) {
    var component = props.data.flatData;

    var { pageName } = useParams();
    var { pageValue } = useParams();

    const [deviceData, setdeviceData] = useState(null);
    const [activeaccordian, setActiveaccordian] = useState(null);
    function handleClick(index) {
        if (activeaccordian === index) {
            setActiveaccordian(null);
        }
        else {
            setActiveaccordian(index);

        }
    }

    useEffect(() => {
        if (pageValue) {
            setdeviceData(null);
            let query = DEVICE_LOOKUP(pageName, pageValue);

            axios({
                url: 'https://cms.computicket.com/api/content/knect/graphql',
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'post',
                data: {
                    query: query
                }
            }).then((result) => {

                setdeviceData(result.data.data);
            });
        }
        else {
        }
        window.scrollTo(0, 0)
    }, [pageValue, pageName]);

    if (!pageValue) {
        return (
            <section className="page-layout-component">
                <div className="devices">
                    <h1 style={{ color: (component.headerColor && component.headerColor.length > 0 ? component.headerColor[0].flatData.colorValue : ''), display: component.header ? '' : 'none' }}>{component.header}</h1>
                    <div style={{ display: component.subHeading ? '' : 'none' }} dangerouslySetInnerHTML={{ __html: component.subHeading }}></div>

                    <div className="device-boxes">

                        {component.actualProducts && component.actualProducts.map((item, index) => {
                            return (
                                <div key={index} className="deviceContainer">

                                    <div key={index} className="device-box">
                                        <img src={(item.flatData && item.flatData.thumbnailImage && item.flatData.thumbnailImage.length > 0 ? item.flatData.thumbnailImage[0].url.slice(0, -1) : '')} alt="" />
                                        <div className="device-shadow" style={{ marginBottom: "30px" }}></div>
                                        <div className="devicePriceContainer">
                                            <div className="device-title">
                                                <h3 >{item.flatData.productDisplayName}</h3>
                                            </div>
                                            <div className="savingsMessage" style={{ backgroundColor: item.flatData.savingsMessageColor && item.flatData.savingsMessageColor.length > 0 ? item.flatData.savingsMessageColor[0].flatData.colorValue : 'white' }}>
                                                {item.flatData.savingsMessage}
                                            </div>
                                            <div className="device-price">
                                                <p  >{item.flatData.cashPrice}</p>
                                            </div>
                                            <div className="device-small-info">
                                                <small >{item.flatData.priceLimitation}</small>
                                            </div>
                                            <div>
                                                <Link className="card_cta_button" style={{ marginTop: '20px' }} to={'/' + pageName + "/" + item.flatData.url}>SEE SPECS</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                        })}


                    </div>
                </div>
            </section>
        )
    }
    else {

        if (!deviceData) {
            return ('')
        }
        else {

            var product = deviceData.queryPhysicalProductContents[0].flatData;
            return (
                <section className="page-layout-component">
                    <div className="single-phone">
                        <h1 style={{ color: (component.headerColor && component.headerColor.length > 0 ? component.headerColor[0].flatData.colorValue : ''), display: product.payOffline ? '' : 'none' }}>{product.payOffline}</h1>
                        <div style={{ display: product.longDescription ? '' : 'none' }} dangerouslySetInnerHTML={{ __html: product.longDescription }}></div>
                        <div className="single-phone-columns" >
                            <div className="single-phone-column-left">
                            <div className="savingsMessage" style={{ backgroundColor: product.savingsMessageColor && product.savingsMessageColor.length > 0 ? product.savingsMessageColor[0].flatData.colorValue : 'white' }}>
                                                {product.savingsMessage}
                                            </div>
                                <img src={(product.largeImage && product.largeImage.length > 0 ? product.largeImage[0].url.slice(0, -1) : '')} alt="" />
                                <div className="device-shadow"></div>
                            </div>
                            <div className="single-phone-column-right">
                                <div className="deviceTitlePrice">
                                    <div className="deviceTitleContainer">
                                        <h3 className="device-title">{product.productDisplayName}</h3>
                                        <small className="device-small" style={{ color: "#378082" }}>
                                            {product.productType}
                                        </small>
                                    </div>
                                    <div className="deviceTitlePriceContainer">
                                        <p className="device-price" style={{ color: "#378082" }}>{product.cashPrice}</p>
                                        <small className="device-small">Cash purchase in store</small>
                                    </div>
                                </div>
                                <hr className="productLine" />
                                <h2 className="specs">Specs</h2>
                                <div className="device-specs" dangerouslySetInnerHTML={{ __html: product.specs }} />
                                <hr className="productLine" />

                                <h3 style={{fontSize : "clamp(14px,2vw,20px)"}}>{product.additionalDetailsHeading}</h3>
                                <div className="deviceaccordionContainer">
                                    {product.extraDetail && product.extraDetail.map((item, index) => {
                                        return (
                                            <div key={index} className={"deviceaccordionItem " + (index === activeaccordian ? 'open' : 'close')}>
                                                <h3 className="deviceAccordianHead" style={{ color: "white" }} onClick={() => handleClick(index)}>{item.extraDetailsHeading}</h3>
                                                <div className="deviceaccordionItemContent" dangerouslySetInnerHTML={{ __html: item.extraDetailsDetail }}>

                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <hr className="productLine" />
                                <div className="single-device-pricing">

                                    {/* <a href="?" className="card_cta_button">FIND A STORE</a> */}
                                    <Link className="card_cta_button" to="/find-a-store">FIND A STORE</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            )
        }
    }
}


export default PhysicalDevicesComponent;
