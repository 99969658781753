import './App.css';

import React, { Fragment, useState, useEffect } from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Global from './components/Global';
import NavbarNew from './components/pageComponents/NavbarNew';
import axios from 'axios';
import Footer from './components/pageComponents/Footer';
import Generic from './pages/Generic';
import { SITE_CONFIG } from './GraphQl/Queries'
const ls = require('localstorage-ttl');

function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    var cache = ls.get("config");

    if (cache) {
      setData(cache);
    } else {
      let query = SITE_CONFIG();

      axios({
        url: 'https://cms.computicket.com/api/content/knect/graphql',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
        data: {
          query: query
        }
      }).then((result) => {
        ls.set("config", result.data.data, [300000]);
        setData(result.data.data);
      });
    }
  }, []);

  if (data) {
    if (window.location.pathname === '/self-rica') {
      window.location.href = 'https://onboarding-web-knectmobile.kyc.business/';
      return null;
    }
    return (
      <Fragment>
        <Global data={data} />
        <BrowserRouter>
          <NavbarNew />
          <Routes>
            <Route 
              path="/:pageName?/:pageValue?" 
              element={<Generic data={data.findSiteContent.flatData.sitePages} />} 
            />
            <Route 
              path="/" 
              element={<Generic data={data.findSiteContent.flatData.sitePages} />} 
            />
          </Routes>
          <Footer />
        </BrowserRouter>
      </Fragment>
    );
  } else {
    return null;
  }
}

export default App;