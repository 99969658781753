import React, { useState, useEffect, Fragment } from "react";
import './Footer.css';
import axios from 'axios';
import { FOOTER_LOOKUP } from '../../GraphQl/Queries'
import './Footer.css';
import { Link } from "react-router-dom";


const ls = require('localstorage-ttl');


function Footer() {


    const [data, setData] = useState(null);
    const [activeaccordian, setActiveaccordian] = useState(null);

    function handleClick(index) {
        if (activeaccordian === index) {
            setActiveaccordian(null);
        }
        else {
            setActiveaccordian(index);

        }
    }

    useEffect(() => {
        var cache = ls.get("footer");

        if (cache) {
            setData(cache);
        }
        else {
            let query = FOOTER_LOOKUP()

            axios({
                url: 'https://cms.computicket.com/api/content/knect/graphql',
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'post',
                data: {
                    query: query
                }
            }).then((result) => {
                setData(result.data.data);
                //10 min chace
                ls.set("footer", result.data.data, [300000]);
            });
        }
    }, []);




    if (data) {
        var component = data.findSiteContent.flatData.footer;

        var columnList = null;
        var imageTextRowList = null;
        var textLinkRowList = null;
        var imageRowList = null;

        for (let num = 0; num < component.length; num++) {
            if (component[num].__typename === 'gql_4Column') {
                columnList = component[num].flatData.columns;
            }
            else if (component[num].__typename === 'ImageTextRow') {
                imageTextRowList = component[num].flatData.linkedContent;
            }
            else if (component[num].__typename === 'TextLinkRow') {
                textLinkRowList = component[num].flatData.linkedContent;
            }
            else if (component[num].__typename === 'ImageRow') {
                imageRowList = component[num].flatData;
            }

        }




        return (

            <section className="page-layout-component" style={{ backgroundColor: "#212B36" }}>
                <div className="footer">
                    <div id="footer-lists" className="footer-lists">
                        {columnList.map((columns, columnsIndex) => {
                            return (
                                <div key={columnsIndex} className={'footer-col ' + (columnsIndex === activeaccordian ? 'openFooter' : 'closedFooter')}>
                                    <h6 className="h6" onClick={() => handleClick(columnsIndex)}>
                                        {columns.flatData.header}
                                    </h6>
                                    <ul className={'footer-list-accordian ' + (columnsIndex === activeaccordian ? 'open' : 'closed')} >
                                        {columns.flatData.columnItems && columns.flatData.columnItems.map((columnItem, columnItemIndex) => {
                                            if (columnItem.__typename === 'WordsAndLinks') {
                                                return (
                                                    <li key={'WordsAndLinks' + columnItemIndex}>
                                                        {columnItem.flatData.isExternal ? <a target="_blank" rel="noreferrer" style={{display : columnItem.flatData.isExternal ? '' : 'none'}} href={columnItem.flatData.externalLink}>{columnItem.flatData ? columnItem.flatData.words : ''}</a> : <Link style={{display : columnItem.flatData.isExternal ? 'none' : ''}}  to={ columnItem.flatData.internalLink && columnItem.flatData.internalLink.length > 0 ? columnItem.flatData.internalLink[0].flatData.urlRoute : '/'} >{columnItem.flatData ? columnItem.flatData.words : ''}</Link>}                                                      
                                                    </li>
                                                )
                                            }
                                            else if (columnItem.__typename === 'ImageRow') {
                                                
                                                
                                                return (
                                                    <div key={'ImageRow' + columnItemIndex} className="footer-social">
                                                        <img src={columnItem.flatData.image[0].flatData.image[0].url.slice(0, -1)} alt="" />
                                                        <div className="footer-social-links">
                                                            {columnItem.flatData.image && columnItem.flatData.image.map((columnItem, columnItemIndex) => {
                                                                
                                                                if (columnItemIndex === 0) {
                                                                    return ('')
                                                                }
                                                                else {
                                                                    return (
                                                                        <Fragment key={columnItemIndex}>
                                                                            <a target="_blank" rel="noreferrer" href={columnItem.flatData.linkUrl  ? columnItem.flatData.linkUrl : ' '} alt={columnItem.flatData.internalDescription}>
                                                                                <img src={columnItem.flatData.image[0].url.slice(0, -1)} alt={columnItem.flatData.internalDescription} />
                                                                            </a>
                                                                        </Fragment>
                                                                    )
                                                                }
                                                            })}
                                                        </div>

                                                    </div>
                                                )
                                            }
                                            else {
                                                return ('')
                                            }

                                        })}

                                    </ul>
                                </div>
                            )

                        })}
                    </div>
                    <div className="footer-mid">
                        <div className="footer-pins">
                            {imageTextRowList.map((imageText, imageTextIndex) => {
                                return (
                                    <div key={imageTextIndex} className="footer-pin-block">
                                        
                                        <img src={imageText.flatData.image[0].url.slice(0, -1)} alt="" />
                                        {imageText.flatData.isExternal ? <a style={{display : imageText.flatData.isExternal ? '' :'none' }} href={imageText.flatData.externalLink}>{imageText.flatData.text}</a> : <Link style={{display : imageText.flatData.isExternal ? 'none' :'' }} to={imageText.flatData.internalLink && imageText.flatData.internalLink.length > 0 ? imageText.flatData.internalLink[0].flatData.urlRoute : '/'}>{imageText.flatData.text}</Link>}
                                    </div>
                                )
                            })}

                        </div>

                        <div className="footer-pages">
                            {textLinkRowList && textLinkRowList.map((text, textIndex) => {
                                return (
                                    <Fragment key={textIndex}>
                                        {text.flatData.isExternal ? <a  target="_blank" rel="noreferrer" href={text.flatData.externalLink} style={{ border: ((textIndex + 1) === textLinkRowList.length ? 'none' : '') , display: text.flatData.isExternal ?  '' : 'none'  }}>{text.flatData.words}</a> : <Link to={text.flatData.internalLink && text.flatData.internalLink.length > 0 ? text.flatData.internalLink[0].flatData.urlRoute : '/'} style={{ border: ((textIndex + 1) === textLinkRowList.length ? 'none' : '') , display: text.flatData.isExternal ?  'none' : '' }}>{text.flatData.words}</Link>}
                                    </Fragment>
                                )
                            })}
                        </div>
                    </div>

                    <div className="footer-logos-container" style={{ borderTop: "1px solid white" }}>
                        <p>{imageRowList.textAbove}</p>
                        <div className="footer-logos">
                            {imageRowList.image && imageRowList.image.map((botImage, botImageIndex) => {

                                return (
                                    <a target="_blank" rel="noreferrer" key={botImageIndex} href={botImage.flatData.linkUrl} className="footer-logo-div" alt={botImage.flatData.internalDescription}>
                                        <img src={botImage.flatData.image[0].url.slice(0, -1)} alt={botImage.flatData.internalDescription}/>
                                    </a>
                                )
                            })}

                        </div>
                    </div>
                </div>

            </section>
        )
    }
    else {
        return ('')
    }
}

export default Footer