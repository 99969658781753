import React, { useEffect, Fragment, useState } from 'react';
import './BundlePricingComponent.css'


function BundlePricingComponent(props) {
    var component = props.data.flatData;

    const [displayAmountText, setDisplayAmountText] = useState("View More Deals");
    const [displayAmountVar, setDisplayAmountVar] = useState(4);
    const [showMore, setShowMore] = useState(false);
    const [showMoreCss, setshowMoreCss] = useState("showmore");
    const [activeaccordian, setActiveaccordian] = useState(null);
    const [displayMoreActive, setDisplayMoreActive] = useState(false);



    useEffect(() => {
        if (component.virtualProductGroups && component.virtualProductGroups.length > 0) {
            if (component.virtualProductGroups[0].flatData.linkedProducts.length > 4) {
                setDisplayMoreActive(true);
            }
        }
    }, [component]);


    function handleClick(index) {
        if (activeaccordian === index) {
            setActiveaccordian(null);
        }
        else {
            setActiveaccordian(index);

        }
    }


    function checkSize(index) {

        if (component.virtualProductGroups && component.virtualProductGroups[index] && component.virtualProductGroups[index].flatData.linkedProducts && component.virtualProductGroups[index].flatData.linkedProducts.length > 4) {
            setDisplayMoreActive(true);
        }
        else {
            setDisplayMoreActive(false);

        }
    }

    function displayAmount() {
        setShowMore(!showMore);
        if (showMore) {
            setDisplayAmountVar(4);
            setDisplayAmountText("View More Deals");
            setshowMoreCss("showmore");
        }
        else {
            setDisplayAmountVar(50);
            setDisplayAmountText("View Less")
            setshowMoreCss("showless");
        }

    }
    return (
        <section className="page-layout-component" style={{ backgroundColor: (component.backgroundColor && component.backgroundColor.length > 0 ? component.backgroundColor[0].flatData.colorValue : ''), color: (component.textColor && component.textColor.length > 0 ? component.textColor[0].flatData.colorValue : '') }}>
            <section className="bundles-container">
                <img className="bundletopleftimg" src={(component.topLeftImage && component.topLeftImage.length > 0 ? component.topLeftImage[0].url.slice(0, -1) : '')} alt="" />
                <img className="bundletoprightimg" src={(component.topRightImage && component.topRightImage.length > 0 ? component.topRightImage[0].url.slice(0, -1) : '')} alt="" />
                <img className="bundlebottomleftimg" src={(component.botLeftImage && component.botLeftImage.length > 0 ? component.botLeftImage[0].url.slice(0, -1) : '')} alt="" />
                <img className="bundlebottomrightimg" src={(component.botRightImage && component.botRightImage.length > 0 ? component.botRightImage[0].url.slice(0, -1) : '')} alt="" />
                <h1>{component.header}</h1>
                <div className="bundles-sub-header" dangerouslySetInnerHTML={{ __html: component.subHeader }}></div>

                <div className="tabs">
                    {component.virtualProductGroups && component.virtualProductGroups.map((item, index) => {

                        return (


                            <Fragment key={index}>

                                <input type="radio" name="tabs" id={'tab' + index} defaultChecked={index === 0} onClick={() => checkSize(index)} />
                                <label htmlFor={'tab' + index}>{item.flatData.description}</label>
                                <div className="tab" >
                                    {!item.flatData.newPriceDisplay && (
                                        <>
                                            <div className="centerdiv" dangerouslySetInnerHTML={{ __html: item.flatData.info }}></div>
                                            {item.flatData.linkedProducts && item.flatData.linkedProducts.slice(0, displayAmountVar).map((productItem, productIndex) => {
                                                if (item.flatData.linkedProducts.length > 4) {
                                                }
                                                return (


                                                    <div key={productIndex} className="bundlesaccordian ">
                                                        <div className={'accordionItem ' + (productIndex === activeaccordian ? 'open' : 'close')}>
                                                            <div className="bunacchead" onClick={() => handleClick(productIndex)}>
                                                                <div className="bunhead">
                                                                    <div className="bunname" dangerouslySetInnerHTML={{ __html: productItem.flatData.displayText }}></div>
                                                                    <div className="bunprice" dangerouslySetInnerHTML={{ __html: productItem.flatData.normalPrice }}></div>
                                                                </div>
                                                                <div className="morebenefit">View Xtra
                                                                    <div className="bunarrow"></div>
                                                                </div>
                                                            </div>
                                                            <div className="bundledetail" >
                                                                {productItem.flatData.linkedBenefits && productItem.flatData.linkedBenefits.map((benefitItem, benefitIndex) => {

                                                                    return (
                                                                        <div key={benefitIndex} className="bundle">
                                                                            <img src={(benefitItem.flatData.benefit && benefitItem.flatData.benefit.length > 0 ? benefitItem.flatData.benefit[0].flatData.image[0].url.slice(0, -1) : '')} alt=""></img>
                                                                            <div className="bddescription">{(benefitItem.flatData.benefit && benefitItem.flatData.benefit.length > 0 ? benefitItem.flatData.benefit[0].flatData.displayText : '')}</div>
                                                                            <div className="bdmessage" dangerouslySetInnerHTML={{ __html: (benefitItem.flatData.benefit && benefitItem.flatData.benefit.length > 0 ? benefitItem.flatData.benefit[0].flatData.displayMessage : '') }}></div>
                                                                            <div className="bdtextdisplay" dangerouslySetInnerHTML={{ __html: benefitItem.flatData.textDisplay }}></div>
                                                                        </div>
                                                                    )
                                                                })}


                                                                <div className="benefitextracontainer">
                                                                    <div className="benefitextradesc" dangerouslySetInnerHTML={{ __html: productItem.flatData.totalBenefitDescription }} ></div>
                                                                    <div className="benefitextraval" dangerouslySetInnerHTML={{ __html: productItem.flatData.totalBenefitDisplay }}></div>
                                                                </div>
                                                                <div className="benefitfooter">
                                                                    <div className="benefitfooterdesc">YOU GET</div>
                                                                    <div className="benefitfooterval" dangerouslySetInnerHTML={{ __html: productItem.flatData.allBenefitsIncludedValue }}></div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <div className="iconcontainer" onClick={displayAmount} style={{ display: displayMoreActive ? '' : 'none' }}>
                                                <div onClick={displayAmount}>{displayAmountText}</div>
                                                <div className={showMoreCss} ></div>
                                            </div>
                                        </>
                                    )}
                                    {item.flatData.newPriceDisplay && (
                                        (() => {
                                            let val = item.flatData.standardRates[0];
                                            let hasStandardRates = false;
                                            let hasSpecialRates = false;
                                            let standardRates;
                                            let specialPrices;


                                            if (item.flatData.standardRates && item.flatData.standardRates.length > 0) {
                                                hasStandardRates = true
                                                standardRates = item.flatData.standardRates[0];
                                            }

                                            if (item.flatData.specialPrices && item.flatData.specialPrices.length > 0) {
                                                hasSpecialRates = true
                                                specialPrices = item.flatData.specialPrices[0];
                                            }
                                            return (
                                                <div className="ratesMainContainer" >
                                                    {hasStandardRates && (
                                                        <div style={{ position: "relative", flex: 1 }}>
                                                            {hasStandardRates.callToAction && (

                                                                <div className="bestRates" style={{ backgroundColor: hasStandardRates.ctaColor && hasStandardRates.ctaColor.length > 0 ? hasStandardRates.ctaColor[0].flatData.colorValue : "" }}>
                                                                    {hasStandardRates.callToAction}
                                                                </div>
                                                            )}
                                                            <div className='ratesCard' style={{ backgroundColor: standardRates.backgroundColor && standardRates.backgroundColor.length > 0 ? standardRates.backgroundColor[0].flatData.colorValue : '' }}>
                                                                {standardRates.logo && standardRates.logo.length > 0 ? (
                                                                    <div className="ratesHeader">
                                                                        <div styleClass='topLogo'>
                                                                            <img src={standardRates.logo[0].url}></img>
                                                                        </div>
                                                                    </div>


                                                                ) : (
                                                                    <div className="ratesHeader">
                                                                        <div className='ratesTopText'>{standardRates.topText}</div>
                                                                    </div>
                                                                )}
                                                                <div className='productsContainer'>
                                                                    {standardRates.linkedProducts && standardRates.linkedProducts.slice(0, 100).map((productItem, productIndex) => {

                                                                        return (
                                                                            <>
                                                                                <hr className='whiteLine' />
                                                                                <div className="ratesLinkedProductsDataContainer">
                                                                                    <div className="ratesName" dangerouslySetInnerHTML={{ __html: productItem.flatData.displayText }}></div>
                                                                                    <div className="bunprice" dangerouslySetInnerHTML={{ __html: productItem.flatData.normalPrice }}></div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </div>
                                                                <div className='ratesFooter'>

                                                                    {standardRates.bottomImage && standardRates.bottomImage.length > 0 && (

                                                                        <div style={{ textAlign: 'center' }}>
                                                                            <img className='bottomImage' src={standardRates.bottomImage[0].url}></img>
                                                                        </div>)}

                                                                    {standardRates.bottomText && (

                                                                        <div >{standardRates.bottomText}</div>
                                                                    )}

                                                                    {standardRates.button && standardRates.button.length > 0 && (
                                                                        <div className='buttonContainer ratesFooter' style={{ textAlign: standardRates.button[0].flatData.position }}>
                                                                            <div style={{ display: 'inline-block', padding: '5px 15px 5px 15px', background: `${standardRates.button[0].flatData.backgroundColor[0].flatData.colorValue}`, borderRadius: '50px' }}>
                                                                                <div>{standardRates.button[0].flatData.buttonText}</div>
                                                                                <div>{standardRates.button[0].flatData.secondaryText}</div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>

                                                            </div>
                                                        </div>

                                                    )}
                                                    {hasSpecialRates && (
                                                        <div style={{ position: "relative", flex: 1 }}>
                                                            {specialPrices.callToAction && (

                                                                <div className="bestRates" style={{ backgroundColor: specialPrices.ctaColor && specialPrices.ctaColor.length > 0 ? specialPrices.ctaColor[0].flatData.colorValue : "" }}>
                                                                    {specialPrices.callToAction}
                                                                </div>
                                                            )}
                                                            <div className='ratesCard' style={{ backgroundColor: specialPrices.backgroundColor && specialPrices.backgroundColor.length > 0 ? specialPrices.backgroundColor[0].flatData.colorValue : '' }}>

                                                                {specialPrices.logo && specialPrices.logo.length > 0 ? (
                                                                    <div className="ratesHeader ratesHeaderSpecial">

                                                                        <div className='topLogo' >
                                                                            <img src={specialPrices.logo[0].url}></img>
                                                                        </div>
                                                                    </div>


                                                                ) : (
                                                                    <div className="ratesHeader">

                                                                        <div className='ratesTopText'>{specialPrices.topText}</div>
                                                                    </div>

                                                                )}
                                                                <div className='productsContainer'>
                                                                    {specialPrices.linkedItems && specialPrices.linkedItems.slice(0, 100).map((productItem, productIndex) => {

                                                                        return (
                                                                            <>
                                                                                <hr className='whiteLine' />
                                                                                <div className="ratesLinkedProductsDataContainer">
                                                                                    <div className="ratesName" dangerouslySetInnerHTML={{ __html: productItem.flatData.displayText }}></div>
                                                                                    <div className="bunprice" dangerouslySetInnerHTML={{ __html: productItem.flatData.normalPrice }}></div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </div>

                                                                <div className='ratesFooter'>

                                                                    {specialPrices.bottomImage && specialPrices.bottomImage.length > 0 && (
                                                                        <img className="bottomImage" src={specialPrices.bottomImage[0].url}></img>
                                                                    )}
                                                                    {specialPrices.bottomText && (

                                                                        <div >{specialPrices.bottomText}</div>
                                                                    )}
                                                                    {specialPrices.button && specialPrices.button.length > 0 && (
                                                                        <div className='buttonContainer' style={{ textAlign: specialPrices.button[0].flatData.position }}>
                                                                            <a href={specialPrices.button[0].flatData.externalLink} style={{ display: 'inline-block', padding: '15px 15px', background: `${specialPrices.button[0].flatData.backgroundColor[0].flatData.colorValue}`, borderRadius: '50px' }}>
                                                                                <div>{specialPrices.button[0].flatData.buttonText}</div>
                                                                                <div>{specialPrices.button[0].flatData.secondaryText}</div>
                                                                            </a>
                                                                        </div>
                                                                    )}
                                                                </div>


                                                            </div>
                                                        </div>

                                                    )}
                                                </div>
                                            )
                                        })()
                                    )}

                                </div>
                            </Fragment>
                        )
                    })}
                </div>
            </section>
        </section>
    )
}

export default BundlePricingComponent;