import React, { useEffect, useState } from 'react';
import './InternationalCallRates.css';
import { CALL_RATES_LOOKUP } from '../../GraphQl/Queries'
import axios from 'axios';


function InternationalCallRates(props) {
    // const [callRates, setCallRates] = useState(null);
    const [dataList, setDataList] = useState([]);
    const [originalList, setOriginalList] = useState("");


    var component = props.data.flatData;


    useEffect(() => {
        let query = CALL_RATES_LOOKUP('nothing yet');

        const items = [];
        axios({
            url: 'https://cms.computicket.com/api/content/knect/graphql',
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
            data: {
                query: query
            }
        }).then((result) => {
            if (result) {
                if (result.data) {
                    if (result.data.data) {
                        var resutlData = result.data.data.queryRatesContents;
                        for (let num = 0; num < resutlData.length; num++) {
                            items.push({ alias: resutlData[num].flatData.alias, alpha2: resutlData[num].flatData.alpha2, alpha3: resutlData[num].flatData.alpha3, countrycode: resutlData[num].flatData.countrycode, countryname: resutlData[num].flatData.countryname, maxRate: resutlData[num].flatData.maxRate })
                        }
                        setOriginalList(items);
                        setDataList(items);
                    }
                }
            }

        })
            .catch(function (error) {
                // handle error
            });

    }, []);

    var inputValue = "";
    function handleChange(e) {
        inputValue = e.target.value;
        var newData = [];
        originalList.filter(item => !inputValue || item.countryname.toLowerCase().includes(inputValue.toLowerCase()) || item.countrycode.includes(inputValue.toLowerCase()))
            .map((item, index) => {
                newData.push(item);
                return('');
            })
        setDataList(newData);
    }



    if (component) {
        return (<section className="page-layout-component" style={{ backgroundColor: component.backgroundColor && component.backgroundColor.length > 0 ? component.backgroundColor[0].flatData.colorValue : ''}}>
            <section className="icrConatianer">
                <div style={{ display: component.header ? '' : 'none' }}><h1 style={{ color: component.headerColor && component.headerColor.length > 0 ? component.headerColor[0].flatData.colorValue : ''}}>{component.header}</h1></div>
                <div style={{ display: component.paragraph ? '' : 'none' }} className="paragraph" dangerouslySetInnerHTML={{ __html: component.paragraph }}></div>
                <input onChange={handleChange} placeholder="Type your country name or code here" />

                <table>
                    <thead>
                        <tr className="extend">
                            <th className="countryColumn">Country</th>
                            <th className="codeColumn" >Code</th>
                            <th className="rateColumn" >Rate/minute</th>
                        </tr>
                    </thead>
                    <tbody className="tableBody">
                        {dataList && dataList.map((resultSeacrh, index) => {
                            return (
                                <tr key={index}>
                                    <td className="countryColumn">{resultSeacrh.countryname}</td>
                                    <td className="codeColumn">{resultSeacrh.countrycode}</td>
                                    <td className="rateColumn">{resultSeacrh.maxRate}</td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>

            </section>
        </section>
        )
    }
    else {
        return ('not data ');
    }
}

export default InternationalCallRates;