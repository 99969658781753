import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { isIE } from 'react-device-detect'

ReactDOM.render(
  <React.StrictMode>
    {isIE ?
      <div className="NScontainer">
        <div className="NSimage">
          <img src="https://cms.computicket.com/api/assets/knect/c4439f75-4dfc-4484-a9a4-b135e7236be5" alt="browser not suported" />
        </div>
        <h1 className="NSheading">Oooh eh eh!</h1>
        <p className="NSmessage">It looks like you’re living in the past! You are running a very old and unsupported browser.
        <br></br>
              Please use one of the browsers below to view this website. </p>
        <div style={{ overflowX: 'auto' }}>
          <table>
            <tbody className="NStableBody">
              <tr>
                <td><img className="NSbrowserimage" src="https://cms.computicket.com/api/assets/knect/19f39bbb-d786-4d9a-8a51-5c4e3c05c24b" alt="opera" /></td>
                <td><img className="NSbrowserimage" src="https://cms.computicket.com/api/assets/knect/ac6bbf30-7847-4c63-a9e7-4bcc5fde285c" alt="firefox" /></td>
                <td><img className="NSbrowserimage" src="https://cms.computicket.com/api/assets/knect/34f13657-00c5-4915-8428-7fc4250d6d8e" alt="Google Chrome" /></td>
                <td><img className="NSbrowserimage" src="https://cms.computicket.com/api/assets/knect/14ebc849-8f8c-47e0-b3e3-fb9926031152" alt="Microsoft Edge" /></td>
              </tr>
              <tr>
                <td className="NSbrowsername">opera</td>
                <td className="NSbrowsername">Firefox</td>
                <td className="NSbrowsername">Google Chrome</td>
                <td className="NSbrowsername">Microsoft Edge</td>
              </tr>
              <tr className="NSlinks">
                <td>
                  <a className="NSbrowserlink" href="https://www.opera.com/">
                    <div className="NSbrowserbutton">GET OPERA</div>
                  </a>
                </td>
                <td>
                  <a className="NSbrowserlink" href="https://www.mozilla.org/en-US/firefox/new/">
                    <div className="NSbrowserbutton">GET FIREFOX</div>
                  </a>
                </td>
                <td>
                  <a className="NSbrowserlink" href="https://www.google.co.za/chrome/">
                    <div className="NSbrowserbutton">GET CHROME</div>
                  </a>
                </td>
                <td>
                  <a className="NSbrowserlink" href="https://www.microsoft.com/en-us/edge">
                    <div className="NSbrowserbutton">GET EDGE</div>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      :
      <App />}
  </React.StrictMode >,
  document.getElementById('root')
);


