import React, { Fragment, useEffect, useState } from 'react';
import './NavbarNew.css';
import { Link } from "react-router-dom";
import axios from 'axios';
import { MENU_ITEMS } from '../../GraphQl/Queries'

const ls = require('localstorage-ttl');
function NavbarNew() {

    const [data, setData] = useState(null);
    const [hoverItem, setHoverItem] = useState(null);
    const [toggle, setToggle] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);


    function menuPressed() {
        window.scrollTo(0, 0)

    }

    useEffect(() => {
        var cache = ls.get("menu");

        if (cache) {
            setData(cache);

        }
        else {

            let query = MENU_ITEMS();

            axios({
                url: 'https://cms.computicket.com/api/content/knect/graphql',
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'post',
                data: {
                    query: query
                }
            }).then((result) => {
                //10 min cache
                ls.set("menu", result.data.data, [300000]);
                setData(result.data.data);
            });
        }

    }, []);


    function testHover(e) {
        if (e.target.className.includes('item')) {
            setHoverItem(parseInt(e.target.id));

        }
    }

    if (data) {

        var menuJson = data.findSiteContent.flatData.siteMenu[0].flatData;

        return (
            <div className="navcontainer" style={{ position: toggle ? 'absolute' : '' }}>
                <nav>
                    <ul id="menu" className={'menu ' + (toggle ? 'activenav' : '')}>
                        <Link className="logo" to="/" aria-label="home">
                            <img src={menuJson.leftLogo && menuJson.leftLogo.length > 0 ? menuJson.leftLogo[0].url.slice(0, -1) : ''} alt="" />
                        </Link>
                        <div className="menucontainer">
                            <ul className="menu">
                                {menuJson.menuItems.map((item, index) => {
                                    return (
                                        <li key={index} id={index} className={'item ' + (index === hoverItem ? 'submenu-activenav ' : '') + (selectedItem === index ? 'selectedMenuItem' : '')} onMouseLeave={() => setHoverItem(null)} onMouseEnter={e => testHover(e)} onClick={() => { setHoverItem(item.flatData.hasSubItems ? (index === hoverItem ? null : index) : setToggle(false)); setSelectedItem(index) }}>
                                            {item.flatData.hasSubItems ? 
                                            <div className={'menulink ' + (item.flatData.hasSubItems ? 'hasItems' : 'hasNoItems')} style={{ display: item.flatData.hasSubItems ? '' : 'none' }}>{item.flatData.menuItemDescription}</div> 
                                            : <Link className={'menulink ' + (item.flatData.hasSubItems ? 'hasItems' : 'hasNoItems')} style={{ display: item.flatData.hasSubItems ? 'none' : '' }} to={{ pathname: (item.flatData.pageLayout && item.flatData.pageLayout.length > 0 ? item.flatData.pageLayout[0].flatData.urlRoute : '/') }} >{item.flatData.menuItemDescription}</Link>}
                                            <ul className="submenu">

                                                {item.flatData.hasSubItems && item.flatData.subMenuItems.map((subMenItem, subMenItemIndex) => {
                                                    return (
                                                        <Fragment key={subMenItemIndex}>
                                                            {item.flatData.hasSubItems ? <li tabIndex="1" onClick={() => { setHoverItem(null); setToggle(false) }} style={{ display: item.flatData.hasSubItems ? '' : 'none' }} className="subitem"><Link to={subMenItem.flatData.pageLayout[0].flatData.urlRoute} >{subMenItem.flatData.description}</Link></li> : <li tabIndex="1" onClick={() => { setHoverItem(null); setToggle(false) }} style={{ display: item.flatData.hasSubItems ? 'none' : '' }} className="subitem"><a href={subMenItem.flatData.exsternalLink}>{subMenItem.flatData.description}</a></li>}
                                                        </Fragment>
                                                    )
                                                })}
                                            </ul>

                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="logoright">
                            <img src={menuJson.rightLogo && menuJson.rightLogo.length > 0 ? menuJson.rightLogo[0].url.slice(0, -1) : ''} alt="" />
                        </div>

                        <li className="toggle" onClick={() => { setToggle(!toggle); menuPressed() }}><div><i className={toggle ? 'fas fa-times' : 'fas fa-bars'}></i></div></li>
                    </ul>
                </nav>
            </div>
        );
    }
    else {
        return ('');
    }
}

export default NavbarNew
