import React, { Fragment } from "react";
import './LeftImageCard.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'



function LeftImageCard(props) {



    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })



    var dataJson = props.data.flatData;

    // var positionStyle = "";
    var gapStyle = "";
    var imageOrder = 0;
    var orderWrap = "wrap";

    if (dataJson.imagePosition === 'image left color top') {
        imageOrder = 0;
        // positionStyle = 'topleft';
        gapStyle = "gap_below";
    }
    else if (dataJson.imagePosition === 'image left color bottom') {
        // positionStyle = 'bottomleft';
        gapStyle = "gap_above"
        imageOrder = 0;

    }
    else if (dataJson.imagePosition === 'image right color top') {
        // positionStyle = 'topright';
        gapStyle = "gap_below"
        imageOrder = 2;
        orderWrap = "wrap-reverse"


    }
    else if (dataJson.imagePosition === 'image right color bottom') {
        // positionStyle = 'bottomright';
        gapStyle = "gap_above"
        imageOrder = 2;
        orderWrap = "wrap-reverse"
    }
    else if (dataJson.imagePosition === 'left') {
        imageOrder = 0;
    }
    else if (dataJson.imagePosition === 'right') {
        imageOrder = 2;
    }

    var buttonsData = [];
    var imgaeRowData = [];

    if (dataJson.additionalConten) {
        for (let num = 0; num < dataJson.additionalConten.length; num++) {
            if (dataJson.additionalConten[num].button) {
                buttonsData.push(dataJson.additionalConten[num].button);
            }
            else {
                imgaeRowData.push(dataJson.additionalConten[num].imgaeRowData);

            }
        }
    }


    if (dataJson.imagePosition === "inline above header") {
        return (
            <section className="page-layout-component" style={{ backgroundColor: (dataJson.backgroundColor && dataJson.backgroundColor.length > 0 ? dataJson.backgroundColor[0].flatData.colorValue : ''), color: (dataJson.textColor && dataJson.textColor.length > 0 ? dataJson.textColor[0].flatData.colorValue : '') }}>
                <section className="headertextimage" style={{ margin: dataJson.marginOverride }}>
                    <div style={{ display: dataJson.image ? '' : 'none' }}>
                        <img style={{ maxWidth: dataJson.imageSize }} src={(dataJson.image ? dataJson.image[0].url.slice(0, -1) : '')} alt="" />
                    </div>
                    <div style={{ display: dataJson.header ? '' : 'none' }}>
                        <h1 style={{ color: (dataJson.headerTextColor ? dataJson.headerTextColor[0].flatData.colorValue : '') }} dangerouslySetInnerHTML={{ __html: dataJson.header }}></h1>
                    </div>
                    <div style={{ display: dataJson.paragraph ? '' : 'none' }} className="paragraph" dangerouslySetInnerHTML={{ __html: dataJson.paragraph }}></div>
                </section>
            </section>
        )
    }
    else {
        var gap = null;
        var imageMoveTop = null;
        var imageMoveLeft = null;
        var position = null;
        

        if (isTabletOrMobile) {
            gap = dataJson.gapSizeMobile;

            
        }
        else {
            gap = dataJson.gapSize;
            if (dataJson.shiftImage) {
                position = 'absolute'
                imageMoveTop = dataJson.imageMoveTop
                if (dataJson.imageMoveLeft) {
                    imageMoveLeft = dataJson.imageMoveLeft
                }
            }
            else
            {
                position = 'unset';
            }

        }

        return (
            <section className="page-layout-component" >
                <section className={'floatimagetext'} style={{ margin: dataJson.marginOverride, flexWrap: orderWrap }}>
                    <div className="gap" style={{ backgroundColor: (dataJson.backgroundColor && dataJson.backgroundColor.length > 0 ? dataJson.backgroundColor[0].flatData.colorValue : ''), top: gapStyle === 'gap_above' ? gap : '', bottom: gapStyle === 'gap_below' ? gap : '' }}>
                    </div>
                    <div className="image-column" style={{ width: dataJson.imageColumnWidth, order: imageOrder}}>
                        <img style={{ width: dataJson.imageSize , top : imageMoveTop ? imageMoveTop : '' , left : imageMoveLeft ? imageMoveLeft : '' ,position : position ? position : '', maxWidth : dataJson.imageMaxWidth}} src={(dataJson.image && dataJson.image.length > 0 ? dataJson.image[0].url.slice(0, -1) : '')} alt="" />
                    </div>
                    <div className="text-column" style={{ width: dataJson.textColumnWidth }}>
                        <h1 style={{ color: (dataJson.headerTextColor && dataJson.headerTextColor.length > 0 ? dataJson.headerTextColor[0].flatData.colorValue : '') }} dangerouslySetInnerHTML={{ __html: dataJson.header }}></h1>
                        <div className="paragraph" style={{ color: (dataJson.textColor && dataJson.textColor.length > 0 ? dataJson.textColor[0].flatData.colorValue : '') }} dangerouslySetInnerHTML={{ __html: dataJson.paragraph }}>
                        </div>
                        {dataJson.additionalContent && dataJson.additionalContent.map((additionalContentList, index1) => {
                            return (
                                <Fragment key={index1}>
                                    <div key={index1} className="buttonRow" style={{justifyContent : dataJson.additionalContentAlignment}}>
                                        {additionalContentList.button && additionalContentList.button.map((buttons, index2) => {
                                            return (
                                                <div key={index2} className="cta_button" >
                                                    {buttons.flatData.isExternal ? <a target="_blank" rel="noreferrer" style={{ display: buttons.flatData.isExternal ? '' : 'none' }} href={buttons.flatData.externalLink} alt="">{buttons.flatData.buttonText}</a> : <Link style={{ display: buttons.flatData.isExternal ? 'none' : '' }} to={buttons.flatData.internalLink[0].flatData.urlRoute} >{buttons.flatData.buttonText}</Link>} 
                                                </div>
                                            )
                                        })}
                                    </div>

                                    {additionalContentList.imageRow && additionalContentList.imageRow.map((imageRow, imageRowIndex) => {
                                        return (
                                            <Fragment key={imageRowIndex}>
                                                <div key={imageRowIndex} className="brandcardrow" style={{ justifyContent: imageRow.flatData.justifyImages }}>
                                                    {imageRow.flatData.image && imageRow.flatData.image.map((image, imageIndex) => {
                                                        return (
                                                            <Fragment key={imageIndex}>

                                                                <div key={imageIndex} className="smallImageCards" >
                                                                    <a target="_blank" rel="noreferrer" href={image.flatData.linkUrl} alt={image.flatData.internalDescription} >
                                                                        <img style={{ width: imageRow.flatData.imageWidth }} src={image.flatData.image && image.flatData.image.length > 0 ? image.flatData.image[0].url.slice(0, -1) : ''} alt={image.flatData.internalDescription} />
                                                                    </a>
                                                                </div>
                                                            </Fragment>
                                                        )
                                                    })}
                                                </div>

                                            </Fragment>
                                        )
                                    })}
                                </Fragment>
                            )
                        })}
                    </div>


                </section>
            </section>
        )
    }
}

export default LeftImageCard;